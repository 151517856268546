.regular_title {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal; }
  .regular_title_0 {
    font-size: 12px; }
  .regular_title_1 {
    font-size: 14px; }
  .regular_title_2 {
    font-size: 16px; }
  .regular_title_3 {
    font-size: 18px; }
  .regular_title_6 {
    font-size: 30px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_1 {
    font-size: 14px; }
  .bold_title_2 {
    font-size: 16px; }

.color_black {
  color: #000000; }

.color_white {
  color: #fff; }

.color_red {
  color: #CF1A20; }

.color_gray {
  color: #828282; }

.color_gray1 {
  color: #333333; }

.color_gray2 {
  color: #272C35; }

.title {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1000px) {
    .title {
      flex-direction: column; }
      .title .title_1 {
        margin-bottom: 20px; } }
  .title_1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    position: relative;
    padding-left: 50px;
    min-width: 30%; }
    @media (max-width: 700px) {
      .title_1 {
        font-size: 20px; } }
    .title_1::before {
      content: '';
      width: 32px;
      height: 1px;
      background: #D9CB9E;
      position: absolute;
      left: 0px;
      top: 15px; }
  .title_2 {
    font-weight: 500;
    font-size: 50px;
    line-height: 59px;
    color: #2C2C2C; }
    @media (max-width: 1000px) {
      .title_2 {
        display: none; } }

.text_std {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #BDC3C7; }
