.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  width: 100%;
  background-color: white;
  padding: 0 5%; }
  @media (max-width: 1000px) {
    .header {
      padding: 0px;
      height: 60px;
      position: fixed; }
      .header .nav {
        opacity: 0;
        position: fixed;
        left: -1000px;
        top: 60px;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
        background: #FFFFFF;
        height: 100vh;
        width: 60vw;
        transition: all .2s;
        padding: 50px; }
      .header .nav__item {
        margin: 0;
        margin-bottom: 50px;
        font-size: 30px; } }
  @media (max-width: 600px) {
    .header .nav {
      width: 100vw;
      padding: 20px; }
    .header .nav__item {
      font-size: 20px; } }
  .header__logo {
    padding: 15px;
    height: 100%;
    display: flex; }
    @media (max-width: 1000px) {
      .header__logo {
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25); } }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 65px;
    height: 60px;
    background: #378858;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (max-width: 1000px) {
      .header_hamburger {
        display: flex; } }
  .header_line {
    transition: all .2s;
    position: absolute;
    width: 30px;
    height: 5px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #FFFFFF; }
    .header_line_1 {
      top: 16px;
      left: 20px; }
    .header_line_2 {
      width: 23px;
      top: 24px;
      left: 20px; }
    .header_line_3 {
      top: 32px;
      left: 20px; }
  .header.active .header_line_1 {
    top: 24px;
    left: 20px;
    transform: rotate(45deg); }
  .header.active .header_line_2 {
    left: -100px; }
  .header.active .header_line_3 {
    top: 24px;
    left: 20px;
    transform: rotate(-45deg); }
  .header.active .nav {
    opacity: 1;
    left: 0;
    z-index: 1000; }

.nav {
  display: flex; }
  .nav .nav__item {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #374140;
    cursor: pointer;
    margin-right: 20px; }
    .nav .nav__item:hover {
      color: #378858; }

.address {
  display: flex;
  align-items: center; }
  .address_phone {
    margin-right: 20px; }
  .address a {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #374140;
    white-space: nowrap;
    display: flex;
    align-items: center; }
    @media (max-width: 500px) {
      .address a {
        margin-right: 10px; }
        .address a img {
          width: 30px;
          height: 30px; } }
    .address a:first-child img {
      margin-top: 15px; }
      @media (max-width: 500px) {
        .address a:first-child img {
          margin-top: 10px; } }
  @media (max-width: 1000px) {
    .address__phone {
      display: none; } }
