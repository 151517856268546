.modal__block {
  display: none;
  transition: all .2s;
  padding: 50px 60px;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px); }
  .modal__block.active {
    display: block;
    z-index: 9999; }
  .modal__block .validation_error {
    bottom: -20px;
    left: 10px; }

.modal_close-x {
  transition: all .2s;
  color: #BDC3C7;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px; }
  .modal_close-x:hover {
    color: #73CAC2; }

.modal_svg {
  margin-right: 8px; }

.modal_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 40px; }
  .modal_title img {
    margin-right: 20px; }

.modal_form {
  margin-top: 40px; }

.modal_forget_btn {
  display: block;
  width: 100%;
  margin: 30px 0;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline; }

.modal_btn {
  display: flex; }
  .modal_btn button, .modal_btn div {
    width: 50%; }
  .modal_btn__block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between; }
    .modal_btn__block .register {
      border: 1px solid; }

.modal__btn-center {
  display: flex;
  justify-content: center; }

.modal_new_user {
  text-transform: uppercase; }

.modal .mailing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }
  .modal .mailing_choice {
    display: flex; }
    .modal .mailing_choice__item {
      margin-right: 20px; }

.modal_auth-social {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .modal_auth-social__title {
    color: #828282;
    font-size: 14px; }
  .modal_auth-social a {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black; }
    .modal_auth-social a img {
      margin-right: 5px; }

@media (max-width: 480px) {
  .modal_btn__block {
    flex-direction: column; }
    .modal_btn__block .modal_btn {
      width: 100%;
      margin-bottom: 10px; }
    .modal_btn__block .modal_open {
      width: 100%; }
  .modal_title {
    justify-content: flex-start;
    padding-right: 30px; }
  .modal__block {
    padding: 15px; }
  .modal_form {
    padding-top: 0px;
    margin-top: 10px; } }

.modal_consultation .input_section {
  width: 100%; }
  .modal_consultation .input_section input {
    width: 100%; }
