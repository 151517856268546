.footer {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1000px) {
    .footer {
      flex-direction: column; }
      .footer .nav {
        flex-direction: column;
        padding: 15px;
        width: 100%; }
        .footer .nav a {
          margin-right: 0;
          margin-bottom: 15px; } }
