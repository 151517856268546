main {
  background: linear-gradient(5.79deg, #010101 85.28%, #363a45 101.11%, #000000 101.11%); }
  @media (max-width: 1000px) {
    main {
      margin: 0; } }

section {
  margin: 0 100px;
  padding-top: 0;
  padding: 40px; }
  @media (max-width: 1000px) {
    section {
      margin: 0; } }
  @media (max-width: 450px) {
    section {
      padding: 20px; } }

.section_bg {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px); }
  .section_bg:not(:last-child) {
    margin-bottom: 20px; }
    @media (max-width: 1000px) {
      .section_bg:not(:last-child) {
        margin-bottom: 0; } }

.banner {
  position: relative; }
  @media (max-width: 1000px) {
    .banner {
      padding-top: 60px; }
      .banner .banner__left {
        position: relative; }
      .banner .banner__title-1 {
        font-size: 35px;
        line-height: 41px; }
      .banner .banner__title-2 {
        font-size: 50px;
        line-height: 59px; }
      .banner .swiper-pagination {
        position: static; }
      .banner .banner__text {
        margin: 20px 0; } }
  .banner__top {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #d9cb9e;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    padding: 10px 20px;
    width: fit-content;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1000; }
    @media (max-width: 1000px) {
      .banner__top {
        position: static;
        width: 100%;
        font-size: 15px;
        line-height: 18px;
        text-align: center; } }
    .banner__top span {
      font-weight: 600; }
      .banner__top span:first-child {
        color: yellow; }
      .banner__top span:last-child {
        color: blue; }
  .banner__img {
    position: absolute;
    z-index: 1000; }
    .banner__img-1 {
      right: 5%;
      bottom: 0; }
      @media (max-width: 1000px) {
        .banner__img-1 {
          display: none; } }
    .banner__img-2 {
      right: 0%;
      bottom: -15%; }
      @media (max-width: 1000px) {
        .banner__img-2 {
          display: none; } }
  .banner__slide img {
    width: 100%;
    max-height: 650px;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5); }
  .banner__left {
    padding: 100px;
    position: absolute;
    z-index: 1000;
    top: 5%;
    left: 0; }
    @media (max-width: 1000px) {
      .banner__left {
        padding: 5%; } }
  .banner__title {
    font-style: normal;
    font-weight: 900; }
    .banner__title-1 {
      font-size: 43px;
      line-height: 50px;
      color: #ffffff; }
    .banner__title-2 {
      font-size: 57px;
      line-height: 67px;
      color: #d9cb9e; }
  .banner__text {
    margin: 40px 0;
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px; }

.calc-and-stages {
  display: flex;
  align-items: flex-start;
  padding: 40px 0; }
  @media (max-width: 1000px) {
    .calc-and-stages {
      padding: 40px; } }
  @media (max-width: 900px) {
    .calc-and-stages {
      flex-direction: column;
      padding: 0; } }

.work {
  padding: 40px;
  text-align: center; }
  @media (max-width: 1300px) {
    .work .title_1 {
      margin-bottom: 20px; } }
  .work .title_2 {
    color: #fff; }
    @media (max-width: 1300px) {
      .work .title_2 {
        display: none; } }
  .work__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px; }
  .work__item {
    position: relative;
    border: 1px solid #bdc3c7;
    width: 48%;
    padding: 30px;
    margin-bottom: 20px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px; }
    .work__item span {
      position: absolute;
      left: 5px;
      top: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 50px;
      line-height: 59px;
      color: #2c2c2c;
      backdrop-filter: blur(2px); }
  @media (max-width: 1440px) {
    .work__item {
      font-size: 14px;
      padding: 10px; }
      .work__item span {
        font-size: 40px; }
    .work__items {
      margin-top: 0px; } }
  @media (max-width: 1100px) {
    .work__item {
      font-size: 12px; }
      .work__item span {
        font-size: 30px; } }
  @media (max-width: 900px) {
    .work {
      padding: 40px; } }
  @media (max-width: 450px) {
    .work {
      padding: 20px; } }

.why_us {
  display: flex; }
  @media (max-width: 1000px) {
    .why_us {
      flex-direction: column; }
      .why_us .why_us__left,
      .why_us .why_us__right {
        width: 100%; }
      .why_us .why_us__text {
        padding: 0;
        margin-top: 20px; }
        .why_us .why_us__text:last-child {
          margin: 20px 0; } }
  @media (max-width: 600px) {
    .why_us .why_us__text-2 {
      font-size: 20px; } }
  .why_us__left {
    width: 60%; }
  .why_us__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #bdc3c7;
    margin-top: 100px;
    padding: 0 100px 0 50px; }
    .why_us__text:last-child {
      margin-top: 40px; }
  .why_us__right {
    width: 40%;
    position: relative; }
    .why_us__right img {
      width: 100%; }
  .why_us .experience {
    width: 305px;
    height: 115px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    padding: 30px 40px;
    position: absolute;
    left: -150px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1000px) {
      .why_us .experience {
        top: 0;
        left: 0;
        padding: 15px; } }
    @media (max-width: 500px) {
      .why_us .experience {
        width: 160px; }
        .why_us .experience .experience__text-1 {
          font-size: 12px; }
          .why_us .experience .experience__text-1 div {
            font-size: 14px; }
        .why_us .experience .experience__text-2 {
          font-size: 12px;
          line-height: 14px; }
          .why_us .experience .experience__text-2 div {
            font-size: 30px;
            line-height: 35px; } }
    .why_us .experience__text-1 {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: #ffffff; }
      .why_us .experience__text-1 div {
        font-size: 30px; }
    .why_us .experience__text-2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #d9cb9e; }
      .why_us .experience__text-2 div {
        font-size: 50px;
        line-height: 59px; }
  .why_us__text-2 {
    font-weight: 300;
    font-size: 25px;
    line-height: 29px;
    color: #d9cb9e;
    margin-top: 30px; }

.services {
  display: flex; }
  @media (max-width: 1000px) {
    .services {
      flex-direction: column; }
      .services .services__left,
      .services .services__right {
        width: 100%; }
      .services .services__left button {
        margin: 20px 0; }
      .services .services__title {
        margin-top: 0; } }
  @media (max-width: 600px) {
    .services .services__item {
      width: 48%; } }
  .services__left {
    width: 40%; }
    .services__left button {
      margin-top: 85px; }
  .services__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .services__title {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 40px; }
  .services__item {
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    width: 30%;
    height: auto;
    margin-bottom: 3%; }
  .services__num {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    color: #374140; }

.partners_swiper {
  margin-top: 50px; }

@media (max-width: 1000px) {
  .about .about__top {
    flex-direction: column; }
  .about .about__left,
  .about .about__right {
    width: 100%; }
  .about .about__right {
    padding: 15px; } }

.about .text_std {
  font-weight: 300; }

.about__top {
  display: flex; }

.about__left {
  width: 50%; }
  .about__left img {
    width: 100%; }

.about__right {
  width: 50%;
  padding-left: 50px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #bdc3c7; }

.about_services {
  margin-top: 50px; }
  .about_services__item {
    padding: 20px;
    background: #000000;
    height: auto; }
    .about_services__item img {
      width: 100%;
      margin-bottom: 20px; }

.about__title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px; }

@media (max-width: 1000px) {
  .contacts .contacts__top {
    flex-direction: column;
    justify-content: flex-start; }
    .contacts .contacts__top button {
      margin-bottom: 20px; }
    .contacts .contacts__top address {
      flex-direction: column;
      margin-right: 0px;
      width: 100%; }
      .contacts .contacts__top address a {
        width: 100%; }
  .contacts iframe {
    height: 250px; } }

.contacts__top {
  display: flex;
  align-items: center;
  margin-top: 30px; }
  .contacts__top address {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 50px; }
    .contacts__top address a {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #bdc3c7;
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 20px; }
