.btn_red {
  background: #CC3759;
  color: white;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  text-align: center; }
  @media (max-width: 1000px) {
    .btn_red {
      width: 100%; } }
  .btn_red:hover {
    box-shadow: 0px 20px 50px -10px rgba(204, 55, 89, 0.3); }
  .btn_red:active {
    box-shadow: inset 0px 20px 50px -10px rgba(204, 55, 89, 0.3); }

.btn_green {
  color: #378858;
  border: 1px solid #378858;
  box-shadow: 0px 20px 50px -10px rgba(204, 55, 89, 0.3);
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  text-align: center; }
  @media (max-width: 1000px) {
    .btn_green {
      width: 100%; } }
  .btn_green:hover {
    color: #FFFFFF;
    background: #378858;
    border: 1px solid #378858;
    box-shadow: 0px 20px 50px -10px rgba(55, 136, 88, 0.2); }

.btn_white {
  background: #FFFFFF;
  width: 210px;
  color: #CE0F18;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px; }
  .btn_white:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }
